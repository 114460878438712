import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { GoogleAuthProvider, signInWithCredential, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import AskQuestion from './AskQuestion';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { doc, setDoc, onSnapshot, getDoc } from 'firebase/firestore';
import ManageAccountPopup from './ManageAccountPopup';
import { auth, firestore } from './firebase';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import MySuperAnswers from './MySuperAnswers';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import SuccessPage from './SuccessPage'; 
import Goals from './Goals';
import { setPersistence, browserLocalPersistence } from "firebase/auth";
import { getIdToken } from "firebase/auth";
import FAQ from './FAQ';
import SubstackWidget from './SubstackWidget'; // Adjust the path as necessary
import VideoCarousel from './VideoCarousel';
import Quests from './Quests';
import CreateQuest from './CreateQuest';
import SharedQuest from './SharedQuest';

// import AnimatedText from './AnimatedText'; // Adjust the path if your file is in a different directory
// import AboutPopup from './AboutPopup';
import termsOfService from './termsOfService'; // Adjust the path as necessary
import OpenAIStream from './OpenAIStream';  // Ensure this is correctly imported
// import star1 from './star1.png'; // Assuming you have star1.png in the same folder
// import star2 from './star2.png'; // Assuming you have star2.png in the same folder
import logo from './logo.png'; // Assuming you have star2.png in the same folder
import Careers from './Careers';
import PartyGame from './PartyGame';
  // import JoinGame from './JoinGame';
  import CreativeGame from './CreativeGame';
  import JoinGamePage from './JoinGamePage';
  import About from './About';
  import { useNavigate, Link } from 'react-router-dom';

  // import sing from './sing.png'; // Assuming you have star1.png in the same folder

  import q from './q.png'; // Assuming you have star1.png in the same folder
// import s from './s.png'; // Assuming you have star1.png in the same folder
// import met from './met.png'; // Assuming you have star1.png in the same folder
// import R from './R.png'; // Assuming you have star1.png in the same folder
// import B from './B.png'; // Assuming you have star1.png in the same folder
// import M from './M.png'; // Assuming you have star1.png in the same folder
import Me from './Me.png'; // Assuming you have star1.png in the same folder
import imagine from './imagine.png'; // Assuming you have star1.png in the same folder
import goals from './goals.png'; // Assuming you have star1.png in the same folder
import gametime from './gametime.png'; // Assuming you have star1.png in the same folder

import MyFlashcards from './components/MyFlashcards';

// import styled from 'styled-components';
// import GameJoinPage from './GameJoinPage';
// import gametime from './gametime.png'; // Assuming you have star1.png in the same folder
// import JoinGame from './JoinGame'; // Adjust the path as needed based on your project structure


import Features from './Features';
import MultiplayerGames from './MultiplayerGames';
import AITutoring from './AITutoring';
import EducationalMemes from './EducationalMemes';

import { signInWithApple } from './AppleSignIn';
import UpgradePopup from './UpgradePopup';

const storage = getStorage();

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Persistence set to local");
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });



const reviews = [

  {
    text:   "Supertutor is fantastic! I love the MEME Creation feature! Helps me study for all my tests." ,
    author: "Viva, Washington University",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2F12312.jpg?alt=media&token=b17afe07-0b18-4f49-a54c-cda5050903f2"
  },
  {
    text:     "The vibe of Supertutor is very young and cool. It gets me excited to learn! Supertutor is much better than its competitors." ,
    author: "Dalal, New York University",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2FScreenshot%202024-08-18%20at%209.40.22%E2%80%AFAM.png?alt=media&token=52e71201-e14a-4ad5-865a-74021d8272b2"
  },
  {
    text:      "The design of this software is relatively beautiful compared to other AI software. This is the superior product." ,
    author: "Jackson, University of East Anglia",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2Fjackson.jpg?alt=media&token=70fd5298-c4e8-4147-a286-c32b206b3197"
  },
  {
    text:      "As a law student, Supertutor is invaluable. It breaks down complex legal concepts easily. It's my favorite tutor 24/7." ,
    author: "Sarah, Georgetown University Law",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2Fistockphoto-1403844061-612x612.jpg?alt=media&token=5896fbe8-cb8b-4301-afe4-db6bdb1aa495"
  },
  {
    text:           "Supertutor is the coolest AI I have ever seen or used! I love the game features. Extremely helpful." ,
    author: "Olivia, Bryan University",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2F1680602006164.jpeg?alt=media&token=1573bfd1-76ab-441f-abf2-8face8f3b12a"
  },
  {
    text:           "I use Supertutor for Embedded Systems (Engineering), and it gives me the right answer every time." ,
    author: "Alex, Syracuse Engineering",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2Falex.png?alt=media&token=58856c78-9b06-4544-adbe-c47badb64dda"
  },
  // {
  //   text: "Supertutor's like ChatGPT Plus & Kahoot times 10. I'm obsessed.",
  //   author: "Wendy, Carnegie Mellon Econ",
  //   imageUrl: "https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2F1695789302863.jpeg?alt=media&token=14d05c67-2406-40a9-89b8-af287b3c8f1b"
  // },
  // {
  //   text:           "I use Supertutor's imagination game to spark creativity in my English class. It's incredible how it helps students come up with unique story ideas." ,
  //   author: "Mr. Rodriguez, Westfield High School",
  //   imageUrl: "https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2F00130_Rodrigues_Joshua_01.jpg?alt=media&token=3ffdf1e5-fccb-489a-b268-e8cd2472d787"
  // },
  {
    text:           "I love the vibe and visuals. It really does make the process of memorization/learning fun. It feels like a video game that transcends standard AI platforms." ,
    author: "Marlowe, Manhattan College",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2F1710337647597.jpeg?alt=media&token=2578c982-7b71-4b4d-91e2-2d1b82e40639"
  },



  // Add the rest of your reviews here...
];


// const StarComponents = ({ star1, star2, animationsCompleted }) => {
//   return (
//     <div>
//       <div className={`star-container star-1 ${animationsCompleted ? 'animate-once' : ''}`}>
//       <img src={star2} alt="Star 1" className="star-image" />
//       </div>
      
//       <div className={`star-container star-2 ${animationsCompleted ? 'animate-once' : ''}`}>
//         <img src={star1} alt="Star 2" className="star-image" />
//       </div>

//       <style jsx>{`
//         .star-container {
//           position: absolute;
//           display: none;
//           opacity: 0;
//         }

//         .star-image {
//           width: 100%;
//           height: 100%;
//           object-fit: contain;
//         }

//         .star-1 {
//           width: 50.16px;
//           height: 50.24px;
//           left: calc(50% + 80px);
//           top: 496px;
//           transform: rotate(1.25deg);
//           z-index: 0.5;
//         }

//         .star-1 .star-image {
//           border-radius: 16px;
//         }

//         .star-2 {
//           width: 50.15px;
//           height: 50.15px;
//           left: calc(50% - 120px);
//           top: 350px;
//           transform: rotate(-12.05deg);
//           z-index: 1;
//         }

//         .star-2 .star-image {
//           border-radius: 11px;
//         }

//         @media (min-width: 768px) {
//           .star-container {
//             display: block;
//           }

//           .star-1 {
//             animation: slideFromRight 1.5s ease-out forwards;
//             animation-delay: 2s; /* Delay to match logo appearance */
//           }

//           .star-2 {
//             animation: slideFromLeft 1.5s ease-out forwards;
//             animation-delay: 2s; /* Delay to match logo appearance */
//           }

//           @keyframes slideFromRight {
//             0% {
//               opacity: 0;
//               transform: translateX(50px) rotate(1.25deg);
//             }
//             100% {
//               opacity: 1;
//               transform: translateX(0) rotate(1.25deg);
//             }
//           }

//           @keyframes slideFromLeft {
//             0% {
//               opacity: 0;
//               transform: translateX(-50px) rotate(-12.05deg);
//             }
//             100% {
//               opacity: 1;
//               transform: translateX(0) rotate(-12.05deg);
//             }
//           }
//         }
//       `}</style>
//     </div>
//   );
// };

async function fetchImageUrls() {
  const imagesRef = ref(storage, 'colleges');
  try {
    const result = await listAll(imagesRef);
    const urlPromises = result.items.map((imageRef) => getDownloadURL(imageRef));
    return Promise.all(urlPromises);
  } catch (error) {
    console.error("Error fetching image URLs:", error);
    return [];
  }
}


async function fetchVideoUrl(fileName = 'demo.mp4') {
  const videoRef = ref(storage, `videos/${fileName}`);
  try {
    return await getDownloadURL(videoRef);
  } catch (error) {
    console.error("Error fetching video URL:", error);
    return null;
  }
}



const Carousel = ({ imageUrls, videoUrl, animationsCompleted }) => {
  const sliderRef = useRef(null);

  const slide = (direction) => {
    if (sliderRef.current) {
      if (direction === 'left') {
        sliderRef.current.slickPrev();
      } else {
        sliderRef.current.slickNext();
      }
    }
  };

  
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
    // infinite: true,
    infinite: false,

    centerMode: false,
    centerPadding: '40px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: '40px',
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: '30px',
        }
      }
    ]
  };
  
  const logoSettings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 480, // breakpoint from window width 480px and down
        settings: {
          slidesToShow: 1, // show only 1 slide in viewport
          slidesToScroll: 1
        }
      }
    ]
  };

      return (
    <div style={{ margin: '20px auto', maxWidth: '800px' }}>
  
        {videoUrl && (
  <div style={{ marginTop: '0px' }}>
  {/* <h2 style={{ textAlign: 'center',   fontSize: '1.2em', // Adjust this to make the text smaller
 }}> */}
<h2 
  className={`slide-in-text ${animationsCompleted ? 'animate-once' : ''}`}
  style={{ 
    fontFamily: "'Poppins', sans-serif",
    fontStyle: 'normal',
    fontWeight: '700',
    textAlign: 'center', 
    fontSize: '1em', 
    maxWidth: '450px', 
    margin: '0 auto', 
    marginBottom: '10px', // Reduced from 20px
    marginTop: '-10px'  // Added negative margin to move text up
  }}
>
  Loved by students & teachers <span className="responsive-text">around the world:</span>
</h2>
      {/* <h2 style={{ 
               fontFamily: "'Poppins', sans-serif",
               fontStyle: 'normal',
               fontWeight: '700',
               //fontSize: '48px',
  textAlign: 'center', 
  fontSize: '1em', // Adjust this to make the text smaller
  maxWidth: '450px', // Adjust this to control the width of the text block
  margin: '0 auto', // This centers the block horizontally
  marginBottom: '20px', // This adds a bottom margin. Change '20px' to the value you want.
  marginTop: '20px' // This adds a bottom margin. Change '20px' to the value you want.

}}>
  Loved by students & professionals around the world:</h2> */}

<Slider {...logoSettings} 
  className={`slide-in-text ${animationsCompleted ? 'animate-once' : ''}`}

style={{ marginTop: '-20px', marginBottom: '30px' }}>
  {imageUrls.map((url, index) => (
    <div key={index}>
      <img
        src={url}
        alt="University Logo"
        style={{ 
          width: '120px', 
          height: '100px', 
          margin: '0 auto', 
          objectFit: 'contain',
          padding: '0 20px' // Add horizontal padding
        }}
      />
    </div>
  ))}
</Slider>
  {/* <Slider {...logoSettings} style={{ marginTop: '-20px', marginBottom: '30px' }}>
  {imageUrls.map((url, index) => (
    <div key={index}>
      <img
        src={url}
        alt="University Logo"
        style={{ 
          width: '120px', 
          height: '100px', 
          margin: '0 auto', 
          objectFit: 'contain',
          padding: '0 20px' // Add horizontal padding
        }}
      />
    </div>
  ))}
</Slider> */}

      {/* <StarComponents star1={star1} star2={star2} /> */}

      {/* <StarComponents star1={star1} star2={star2} animationsCompleted={animationsCompleted} /> */}







      <div style={{
      position: 'relative',
      maxWidth: '1000px',
      margin: '20px auto',
      padding: '0 50px',
      height: '300px', // Fixed height

    }}>

<Slider ref={sliderRef} {...settings}>
  {reviews.map((review, index) => (
    <div key={index} style={{ padding: '0 25px' }}>
      <div style={{
        backgroundColor: '#ffffff',
        border: '2px solid #007bff',
        borderRadius: '10px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Arial, sans-serif',
        height: '300px', // Fixed height
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        margin: '0 15px',
      }}>
       <div>
    <div style={{ fontSize: '1em', fontWeight: 'bold', marginBottom: '10px' }}>⭐⭐⭐⭐⭐</div>
    <p style={{ fontStyle: 'italic', margin: '10px 0' }}>{review.text}</p>
  </div>
  <div style={{ 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center',
    marginTop: 'auto',
    marginBottom: '10px'
  }}>
    <img
      src={review.imageUrl}
      alt={review.author}
      style={{ 
        width: '50px', 
        height: '50px', 
        borderRadius: '50%', 
        objectFit: 'cover', 
        marginBottom: '10px' 
      }}
    />
    <p style={{ fontSize: '0.8em', color: '#666' }}>{review.author}</p>
  </div>
</div>
    </div>
  ))}
</Slider>
<button
  onClick={() => slide('left')}
  style={{
    position: 'absolute',
    left: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'rgb(0, 240, 255)', // Updated background color
    color: 'blue',
    border: 'none',
    borderRadius: '50%',
    width: '30px', // Reduced size
    height: '30px', // Reduced size
    fontSize: '16px', // Smaller font size for the arrow
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease'
  }}
  onMouseEnter={(e) => e.target.style.transform = 'translateY(-50%) scale(1.1)'}
  onMouseLeave={(e) => e.target.style.transform = 'translateY(-50%) scale(1)'}
>
  &#8249;
</button>
      {/* <button
        onClick={() => slide('left')}
        style={{
          position: 'absolute',
          left: '0',
          top: '50%',
          transform: 'translateY(-50%)',
          background: '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          fontSize: '20px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 10
        }}
      >
        &#8249;
      </button> */}



<button
  onClick={() => slide('right')}
  style={{
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'rgb(0, 240, 255)',
    color: 'blue',
    border: 'none',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    fontSize: '16px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease'
  }}
  onMouseEnter={(e) => e.target.style.transform = 'translateY(-50%) scale(1.1)'}
  onMouseLeave={(e) => e.target.style.transform = 'translateY(-50%) scale(1)'}
>
  &#8250;
</button>
    </div>


<div id="features" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px', marginTop: '30px' }}>

</div>


<div style={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '500px', margin: '20px auto' }}>
 
 
 
</div>



  </div>
)}
     
    
    </div>
  );
};


function App() {
  const [isHost, setIsHost] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

   // Add this line:
  //  const [isFirstVisit, setIsFirstVisit] = useState(() => {
  //   const visited = localStorage.getItem('hasVisited');
  //   return !visited;
  // });


  // Add this useEffect:
  // useEffect(() => {
  //   if (isFirstVisit) {
  //     localStorage.setItem('hasVisited', 'true');
  //   }
  // }, [isFirstVisit]);

  // const resetAnimations = () => {
  //   setIsFirstVisit(true);
  //   setTimeout(() => setIsFirstVisit(false), 100);
  // };

  // const navigate = useNavigate();
  //const [isGuest, setIsGuest] = useState(false);
  const [isGuest] = useState(false);
  const [showSignInOptions, setShowSignInOptions] = useState(false);

  // const navigate = useNavigate();

  // const [showAboutPopup, setShowAboutPopup] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  // const handleTermsClick = (e) => {
  //   e.stopPropagation();
  //   setShowTermsPopup(true);
  // };
  const handleTermsClick = (e) => {
    e.stopPropagation();
    setShowTermsPopup(true);
  };

  const handleStartLearningClick = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setShowSignInOptions(true);
  };
  // const [animationsCompleted, setAnimationsCompleted] = useState(false);
   // Replace your current animationsCompleted state with this:
   const [animationsCompleted, setAnimationsCompleted] = useState(() => {
    return localStorage.getItem('animationsCompleted') === 'true';
  });
  
  // Update your useEffect
  useEffect(() => {
    if (!animationsCompleted) {
      const timer = setTimeout(() => {
        setAnimationsCompleted(true);
        localStorage.setItem('animationsCompleted', 'true');
      }, 4000);
      
      return () => clearTimeout(timer);
    }
  }, [animationsCompleted]);

    // Add animation state to localStorage
    // useEffect(() => {
    //   const hasSeenAnimation = localStorage.getItem('hasSeenAnimation');
    //   if (hasSeenAnimation) {
    //     setAnimationsCompleted(true);
    //   } else {
    //     localStorage.setItem('hasSeenAnimation', 'true');
    //   }
    // }, []);
  
  const closeTermsPopup = () => {
    setShowTermsPopup(false);
  };
  const toggleButtonSetting = (buttonName) => {
    setButtonSettings((prevSettings) => {
      const updatedSettings = {
        ...prevSettings,
        [buttonName]: !prevSettings[buttonName],
      };
      // Save the updated buttonSettings to Firestore
      if (auth.currentUser) {
        const userRef = doc(firestore, 'users', auth.currentUser.uid);
        setDoc(userRef, { buttonSettings: updatedSettings }, { merge: true });
      }
      return updatedSettings;
    });
  };
  
  const saveButtonSettings = async () => {
    if (auth.currentUser) {
      const userRef = doc(firestore, 'users', auth.currentUser.uid);
      await setDoc(userRef, { buttonSettings }, { merge: true });
    }
  };

  const checkMark = <span style={{color: '#22c55e', fontSize: '24px'}}>✓</span>;
  const xMark = <span style={{color: '#ef4444', fontSize: '24px'}}>✗</span>;

  const [customizeButtonsPopup, setCustomizeButtonsPopup] = useState(false);
  const [buttonSettings, setButtonSettings] = useState({
    quizGame: true,
    metaphor: true,
    biggerVision: true,
    mnemonic: true,
    makeMeme: true,
    academicSources: true,
  });

  // const [clickedIndex, setClickedIndex] = useState(null);

  // const features = [
  //   { src: q, alt: 'Supertutor Quiz', tooltip: 'Generates Original Quiz games' },
  //   { src: sing, alt: 'Supertutor sing', tooltip: 'Generates Original Songs' },

  //   // { src: s, alt: 'Supertutor Storytime', tooltip: 'Superhero storytelling generative capabilities' },
  //   // { src: met, alt: 'Met Feature', tooltip: 'Memory-boosting metaphors' },
  //   // { src: R, alt: 'Supertutor Rhymes', tooltip: 'Rapid rhyme creations' },
  //   // { src: B, alt: 'B Feature', tooltip: 'Gives you the bigger vision to learn better' },
  //   // { src: M, alt: 'M Feature', tooltip: 'Memory-enhancing mnemonics' },
  //   { src: Me, alt: 'Me Feature', tooltip: 'Unlimited access to meme generator' },
  //   { src: imagine, alt: 'Supertutor Imagine Feature', tooltip: 'Unlimited access to Dall-E image generator' },
  //   { src: goals, alt: 'Supertutor Goals Feature', tooltip: 'Goal-setting, boosting & tracking' },
  //   { src: gametime, alt: 'Supertutor Games Feature', tooltip: 'Multiplayer trivia and imagintion games for fun learning' },
  // ];

  const features = [
    {
      src: q,
      alt: 'Interactive Quizzes',
      title: "Interactive Quizzes",
      description: "AI-powered adaptive quizzes",
      stat: "Enhanced retention",
      detail: "Active recall through quizzing improves memory retention compared to passive reading"
    },
    // {
    //   src: sing,
    //   alt: 'Custom Learning Songs',
    //   title: "Custom Learning Songs",
    //   description: "AI-generated musical mnemonics",
    //   stat: "73% better recall",
    //   detail: "Musical learning increases information retention by 73% through emotional connection"
    // },
    {
      src: Me,
      alt: 'Educational Memes',
      title: "Educational Memes",
      description: "Personalized visual learning",
      stat: "Memorable learning",
      detail: "Visual learning through memes increases memory retention through humor and emotional engagement"
    },
    {
      src: imagine,
      alt: 'Visual Learning', 
      title: "Visual Learning",
      description: "AI-generated study visuals",
      stat: "Better comprehension",
      detail: "Visual aids improve information retention compared to text-only materials"
    },
    {
      src: goals,
      alt: 'Goal Tracking',
      title: "Goal Tracking", 
      description: "Smart progress monitoring",
      stat: "Improved outcomes",
      detail: "Goal setting and tracking improves learning outcomes through focused objectives"
    },
    {
      src: gametime,
      alt: 'Multiplayer Games',
      title: "Multiplayer Games",
      description: "Social learning experiences", 
      stat: "Higher engagement",
      detail: "Collaborative learning games increase engagement and improve long-term retention"
    }
  ];
  const [showManageAccountPopup, setShowManageAccountPopup] = useState(false);
  const [userName, setUserName] = useState('');
  const AppleSignInButton = ({ onClick }) => (
    <button
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: 'black',
        color: 'white',
        fontFamily: 'SF Pro Text, sans-serif',
        fontSize: '14px',
        fontWeight: '500',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        width: '200px', // Match the width of the Google button
        height: '40px',
      }}
    >
      <svg viewBox="0 0 24 24" width="18" height="18" style={{ marginRight: '10px' }}>
        <path fill="currentColor" d="M12.152 6.896c-.948 0-2.415-1.078-3.96-1.04-2.04.027-3.91 1.183-4.961 3.014-2.117 3.675-.546 9.103 1.519 12.11 1.013 1.454 2.208 3.09 3.792 3.039 1.52-.065 2.09-.987 3.935-.987 1.831 0 2.35.987 3.96.948 1.637-.026 2.676-1.48 3.676-2.948 1.156-1.688 1.636-3.325 1.662-3.415-.039-.013-3.182-1.221-3.22-4.857-.026-3.04 2.48-4.494 2.597-4.559-1.429-2.09-3.623-2.324-4.39-2.376-2-.156-3.675 1.09-4.61 1.09zM15.53 3.83c.843-1.012 1.4-2.427 1.245-3.83-1.207.052-2.662.805-3.532 1.818-.78.896-1.454 2.338-1.273 3.714 1.338.104 2.715-.688 3.559-1.701"/>
      </svg>
      Sign in with Apple
    </button>
  );

  // const [showArrows, setShowArrows] = useState(false);
  const [user, setUser] = useState(null);
  const [hasValidSubscription, setHasValidSubscription] = useState(false);
  const [loading, setLoading] = useState(true);

  const [imageUrls, setImageUrls] = useState([]);

  const [demoVideoUrl, setDemoVideoUrl] = useState('');


  const handleGoogleFailure = () => {
    console.error("Google Sign-In was unsuccessful. Try again later");
    // You can add your own error handling here
  };

  const CustomGoogleButton = ({ onClick }) => (
    <button
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 20px',
        border: '1px solid #dadce0',
        borderRadius: '4px',
        backgroundColor: 'white',
        color: '#3c4043',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '14px',
        fontWeight: '500',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        width: '200px',
        height: '40px',
      }}
    >
      <img 
        src="https://developers.google.com/identity/images/g-logo.png" 
        alt="Google logo" 
        style={{ width: '18px', height: '18px', marginRight: '10px' }} 
      />
      Sign in with Google
    </button>
  );


  
  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const credential = GoogleAuthProvider.credential(credentialResponse.credential);
      const userCredential = await signInWithCredential(auth, credential);
      const user = userCredential.user;

        // Store the token
    const token = await getIdToken(user);
    localStorage.setItem('authToken', token);
      
      const userRef = doc(firestore, `users/${user.uid}`);
      const userDoc = await getDoc(userRef);
  
      let userCredits = 3; // Default credit value
  
      if (!userDoc.exists()) {
        await setDoc(userRef, {
          name: user.displayName,
          email: user.email,
          subscriptionPlan: "free",
          credits: userCredits,
          lastResetDate: new Date().toDateString(),
        });
      } else {
        await setDoc(userRef, {
          name: user.displayName,
          email: user.email,
        }, { merge: true });
      }
  
      setUser(user);
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  async function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    try {
      await setPersistence(auth, browserLocalPersistence);

      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userRef = doc(firestore, `users/${user.uid}`);
      const userDoc = await getDoc(userRef);
  
      let userCredits = 3; // Default credit value
  
      if (!userDoc.exists()) {
        // User document doesn't exist, create a new one with initial credits and lastResetDate
        await setDoc(userRef, {
          name: user.displayName,
          email: user.email,
          subscriptionPlan: "free",
          credits: userCredits,
        lastResetDate: new Date().toDateString(),
        });
      } else {
        // User document already exists, merge the data without overwriting credits
        await setDoc(userRef, {
          name: user.displayName,
          email: user.email,
        }, { merge: true });
      }
  
      // This will trigger the useEffect hook to update the user state
      setUser(user);
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  }

  // const lines = [
  //   <><span className="highlight">Faster </span> than ChatGPT</>,
  //   <><span className="highlight">Smarter </span> than your teacher</>,
  //   <><span className="highlight">More creative </span> than Kahoot...</>,
  //   <>It's a bird...It's a plane...It's...</>
  // ];

  const lines = [
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '800px',
        margin: '0 auto',
        padding: '0 15px', // Reduced padding
        marginTop: '-10px'
      }}>
        {/* Main headline */}
        <div 
          style={{ 
            fontSize: 'clamp(0.9em, 2.5vw, 2.2em)',
            fontWeight: '900',
            color: '#000000',
            fontStyle: 'normal',
            textAlign: 'center',
            lineHeight: '1.3',
            letterSpacing: '-0.01em',
            marginBottom: '15px',
            marginTop: '10px',
            textShadow: '2px 2px 4px rgba(0,0,0,0.08)',
            fontFamily: "'Poppins', sans-serif",
            width: '85%',
            maxWidth: '500px',
            margin: '0 auto',
            padding: '0 10px',
            wordWrap: 'break-word',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px'
          }}
        >
          <div style={{ marginBottom: '2px' }}><i>FASTER</i> than ChatGPT...</div>
          <div style={{ marginBottom: '2px' }}>More <i>CREATIVE</i> than Kahoot...</div>
          <div style={{ marginBottom: '25px' }}><i>SMARTER</i> than your human tutor!</div>
        </div>
  
        {/* Subheadline */}
        {/* <div 
          style={{
            fontSize: 'clamp(1em, 2vw, 1.5em)', // Reduced size range
            color: '#666',
            fontWeight: '600',
            lineHeight: '1.2',
            textAlign: 'center',
            letterSpacing: '0.01em',
            marginBottom: '12px', // Reduced spacing
            fontFamily: "'Poppins', sans-serif",
            width: '100%',
            wordWrap: 'break-word'
          }}
        >
Easy-to-use tools to accelerate learning, improve memory, and make studying fun"
</div> */}
  
        {/* Description text */}
        {/* <div 
          style={{
            fontSize: 'clamp(0.8em, 1.5vw, 1em)', // Reduced size range
            color: '#777',
            fontWeight: '400',
            lineHeight: '1.3',
            textAlign: 'center',
            maxWidth: '100%', // Use full width on mobile
            margin: '0 auto 15px',
            padding: '0 10px',
            fontFamily: "'Poppins', sans-serif",
            wordWrap: 'break-word'
          }}
        >
        Easy-to-use tools to accelerate learning, improve memory, and make studying fun 
        </div>
   */}
        {/* Logo container */}
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '0',
          marginBottom: '-30px', // Reduced negative margin
          transform: 'translateY(-15px)' // Reduced transform
        }}>
          <img 
            src="/supertutorlogo.png" 
            alt="Supertutor" 
            style={{
              height: 'clamp(100px, 20vw, 150px)', // Responsive height
              transition: 'transform 0.3s ease',
              filter: 'drop-shadow(0px 4px 8px rgba(0,0,0,0.15))'
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'scale(1.05)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'scale(1)';
            }}
          />
        </div>
      </div>
    </>
  ];
  

  const LandingPage = ({ 
    handleGoogleSuccess, 
    handleGoogleFailure,
    signInWithGoogle, 
    signInWithApple, 
    imageUrls, 
    demoVideoUrl, 
    handleTermsClick,
    showTermsPopup,
    closeTermsPopup,
    termsOfService,
    showSignInOptions,
    setShowSignInOptions,
    handleStartLearningClick

  }) => {    

    return (
      <>
      <div style={{ display: 'flex',transform: 'translateZ(0)', flexDirection: 'column', alignItems: 'center' }}>


      <h1 style={{
  fontFamily: "'Poppins', sans-serif",
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: 'clamp(24px, 5vw, 48px)',
  marginTop: '-45px',
  marginBottom: '0', // Reduced from 10px to 0
  lineHeight: '1',   // Reduced from 1.2 to 1
  color: 'black',
  textAlign: 'center'
}}>
  <img 
    src={logo} 
    alt="SupertutorLogo" 
    className={`growing-logo ${animationsCompleted ? 'animate-once' : ''}`}
    style={{
      width: 'clamp(140px, 30vw, 190px)', 
      marginTop: '40px',
      marginBottom: '-20px' // Increased negative margin to pull content up more
    }} 
  />
  <br />
</h1>
    
<h2 
  className={`tagline ${animationsCompleted ? 'animate-once' : ''}`}
  style={{ 
    fontFamily: "'Poppins', sans-serif",
    textAlign: 'center', 
    margin: '30px auto',
    padding: '20px',
    position: 'relative',
    zIndex: 1
  }}
>
  {lines.map((line, index) => (
    <div 
      key={index} 
      className="fade-in-slide-up"
      style={{
        opacity: 0,
        animation: 'fadeInSlideUp 1s ease forwards',
        animationDelay: `${index * 0.3}s`
      }}
    >
      {line}
    </div>
  ))}
</h2>


      </div>
 

        {showSignInOptions ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
          

{showSignInOptions ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleFailure}
            useOneTap
            render={renderProps => (
              <CustomGoogleButton onClick={renderProps.onClick} />
            )}
          />
          <AppleSignInButton onClick={signInWithApple} />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
      <button
  // className="startButton growing-logo"
  className={`startButton ${animationsCompleted ? 'animate-once' : 'growing-logo'}`}

  onClick={(e) => {
    e.preventDefault(); // Prevent default form submission

    handleStartLearningClick(e);
  }}
  style={{
                fontSize: '1.4em',
                fontFamily: "'Poppins', sans-serif",
            fontStyle: 'normal',
          //  fontWeight: '300',
                padding: '10px 20px',
                // background: '#98FB98',
                background: 'rgb(0, 240, 255)', // Background color using RGB values
                                border: '2px solid black',
                borderRadius: '15px',
                boxShadow: '3px 3px 0 black',
                transition: 'all 0.2s ease',
              }}
            >
Start         </button>
        </div>
      )}





  
            {/* Add JoinGame component here if needed */}
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>


<button
  className="startButton"
  onClick={handleStartLearningClick}
  style={{
    fontSize: '1.125rem',
    fontWeight: '600',
    padding: '16px 32px',
    background: 'black',
    color: 'white',
    border: 'none',
    borderRadius: '12px',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  }}
>
  Get Started
</button>
          </div>
        )}

        {/* <Carousel imageUrls={imageUrls} videoUrl={demoVideoUrl} /> */}
        <Carousel imageUrls={imageUrls}
        
        videoUrl={demoVideoUrl} 
        
        animationsCompleted={animationsCompleted} />


        
{/*   
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2>Supertutor makes you custom educational memes to enhance your comprehension & memory:</h2>
          <h2>"Visual aids are proven to give you an 83% increase in memory retention!"</h2>
          <h2 style={{ fontSize: '8px' }}>https://www.shiftelearning.com/blog/bid/350326/studies-confirm-the-power-of-visuals-in-elearning</h2>
        </div> */}
<div style={{ padding: '40px 20px' }}>
  <h2 style={{
    fontFamily: "'Poppins', sans-serif",
    fontStyle: 'normal',
    fontSize: 'clamp(1.5rem, 3vw, 2rem)',
    textAlign: 'center',
    marginBottom: '40px',
    fontWeight: '700'
  }}>
    Supertutor's powerful features
  </h2>
 <div style={{
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
  gap: '25px',
  maxWidth: '1200px',
  margin: '0 auto'
}}>
  {features.map((feature, index) => (
    <div
      key={index}
      style={{
        background: 'white',
        borderRadius: '24px',
        padding: '32px',
        boxShadow: '0 4px 24px rgba(0, 0, 0, 0.06)',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        cursor: 'pointer',
      }}>
      <div style={{
        width: '140px',
        height: '120px',
        margin: '0 auto 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <img
  src={feature.src}
  alt={feature.title}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            transform: 'rotate(-3deg)',
            transition: 'transform 0.3s ease'
          }}
          onMouseEnter={(e) => {
            e.target.style.transform = 'rotate(0deg) scale(1.05)';
          }}
          onMouseLeave={(e) => {
            e.target.style.transform = 'rotate(-3deg) scale(1)';
          }}
        />
      </div>
      <h3 style={{
      fontFamily: "'Poppins', sans-serif",
      fontStyle: 'normal',
      fontSize: '1.25rem',
      fontWeight: '600',
      margin: '0',
      textAlign: 'center'
    }}>
      {feature.title}
      </h3>
      <p style={{
           fontFamily: "'Poppins', sans-serif",
           fontStyle: 'normal',
          
        color: '#666',
        margin: '0',
        fontSize: '0.95rem',
        textAlign: 'center'
      }}>
        {feature.description}
      </p>
      <div style={{
        marginTop: 'auto',
        fontFamily: "'Poppins', sans-serif",
        fontStyle: 'normal',
       
        paddingTop: '12px',
        borderTop: '1px solid #eee',
        textAlign: 'center'
      }}>
        <span style={{
          display: 'block',
          fontFamily: "'Poppins', sans-serif",
          fontStyle: 'normal',
         
          color: '#2196F3',
          fontWeight: '600',
          fontSize: '1.1rem'
        }}>
          {feature.stat}
        </span>
        <span style={{
             fontFamily: "'Poppins', sans-serif",
             fontStyle: 'normal',
            
          fontSize: '0.85rem',
          color: '#777'
        }}>
          {feature.detail}
        </span>
      </div>
    </div>
  ))}
</div>

{/* 

<div style={{ 
  marginTop: '40px', 
  fontFamily: "'Comic Sans MS', 'Chalkboard SE', 'Comic Neue', sans-serif", 
  maxWidth: '1000px', 
  margin: '0 auto', 
 // background: '#fffee0', 
  padding: '20px', 
  borderRadius: '20px', 
 // boxShadow: '0 0 20px rgba(0,0,0,0.2)'
}}>
      <h2 style={{ 
               fontFamily: "'Poppins', sans-serif",
               fontStyle: 'normal',
               fontWeight: '700',
               //fontSize: '48px',
  textAlign: 'center', 
  fontSize: '1.4em', // Adjust this to make the text smaller
  maxWidth: '450px', // Adjust this to control the width of the text block
  margin: '0 auto', // This centers the block horizontally
  marginBottom: '20px', // This adds a bottom margin. Change '20px' to the value you want.
  marginTop: '-10px' // This adds a bottom margin. Change '20px' to the value you want.

  }}>

    
    Supertutor's powerful features:
  </h2>
  
  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '30px' }}>
    {features.map((item, index) => (
      <div
        key={index}
        style={{
          position: 'relative',
          width: '140px',
          height: '120px',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          borderRadius: '15px',
        //  boxShadow: '0 6px 0 #888, 0 8px 10px rgba(0,0,0,0.3)',
          transform: 'rotate(-3deg)',
          transition: 'transform 0.3s',
        }}
        onClick={() => setClickedIndex(clickedIndex === index ? null : index)}
      >
        <img
          src={item.src}
          alt={item.alt}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain'
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '5px',
            left: '5px',
            right: '5px',
            bottom: '5px',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
            textAlign: 'center',
            opacity: 0,
            pointerEvents: 'none',
            borderRadius: '12px',
            boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
            border: '3px solid #1565c0',
          }}
          className={`tooltip ${clickedIndex === index ? 'clicked' : ''}`}
        >
          <span className="comic-text">{item.tooltip}</span>
        </div>
      </div>
    ))}
  </div> */}



  <style jsx>{`
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }

  .animate-text {
    animation: fadeIn 0.8s ease-out forwards;
  }
`}</style>

  <style jsx>{`
  @keyframes fadeInSlideUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .tagline {
    text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  }

  .tagline span {
    display: inline-block;
  }

  .fade-in-slide-up {
    opacity: 0;
    transform: translateY(20px);
  }
`}</style>

  <style jsx>{`
    .tooltip {
      transition: opacity 0.2s;
    }
    // div > div:hover {
    //   transform: rotate(0deg) scale(1.05);
    // }
    div > div:hover > .tooltip,
    .tooltip.clicked {
      opacity: 1 !important;
    }
    .comic-text {
      font-size: 14px;
      font-weight: bold;
      color: #e53935;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      line-height: 1.2;
      text-shadow: 1px 1px 0 #fdd835;
    }
  `}</style>
</div>



<VideoCarousel />
<FAQ />


 

 

  <div style={{ paddingTop: '10px', paddingBottom: '20px' }}>


  {/* <JoinGame /> */}

  
</div>


<div style={{
  fontFamily: 'Arial, sans-serif',
  maxWidth: '100%',
  margin: '0 auto',
  padding: '20px',
  boxSizing: 'border-box'
}}>
  <style>
    {`
      @media (max-width: 768px) {
        table, th, td {
          font-size: 14px;
        }
        th, td {
          padding: 8px 4px;
        }
      }
    `}
  </style>
  
  <h2 style={{
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
    fontSize: 'clamp(1.5rem, 4vw, 2rem)'
  }}>How Supertutor Compares</h2>

  <div style={{
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    overflowX: 'auto'
  }}>
    <table style={{
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: '0 10px',
      fontSize: 'clamp(0.8rem, 2.5vw, 1rem)'
    }}>
      <thead>
        <tr>
          <th style={{
            padding: '12px',
            textAlign: 'left',
            borderBottom: '2px solid #f0f0f0',
            color: '#333',
            fontWeight: 'bold',
            width: '25%'
          }}>Features</th>
          <th style={{
            padding: '12px',
            textAlign: 'center',
            borderBottom: '2px solid #f0f0f0',
            color: 'rgb(65, 105, 225)',
            fontWeight: 'bold',
            border: '2px solid rgb(65, 105, 225)',
            borderRadius: '20px'
          }}>
            SUPERTUTOR
          </th>
          <th style={{
            padding: '12px',
            textAlign: 'center',
            borderBottom: '2px solid #f0f0f0',
            color: '#333',
            fontWeight: 'bold'
          }}>ChatGPT Plus</th>
          <th style={{
            padding: '12px',
            textAlign: 'center',
            borderBottom: '2px solid #f0f0f0',
            color: '#333',
            fontWeight: 'bold'
          }}>Chegg</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Price</td>
          <td style={{padding: '12px', color: '#666', textAlign: 'center'}}>$9.99/month</td>
          <td style={{padding: '12px', color: '#666', textAlign: 'center'}}>$20/month</td>
          <td style={{padding: '12px', color: '#666', textAlign: 'center'}}>$19.95/month</td>
        </tr>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Engaging personalized answers</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
        </tr>
        {/* <tr>
          <td style={{padding: '12px', color: '#666'}}>Generate and sings original songs</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
        </tr> */}
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Memory-boosting memes</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
        </tr>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Unlimited GPT-4o access</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
        </tr>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Dall-E image generation</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
        </tr>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Goal tracking and boosting</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
        </tr>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Upload PDF and images</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
        </tr>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Multiplayer trivia & imagination games</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>



{showSignInOptions ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleFailure}
            useOneTap
            render={renderProps => (
              <CustomGoogleButton onClick={renderProps.onClick} />
            )}
          />
          <AppleSignInButton onClick={signInWithApple} />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
     <button
  className="startButton"
  onClick={handleStartLearningClick}
  style={{
    fontSize: '1.125rem',
    fontWeight: '600',
    padding: '16px 32px',
    background: 'black',
    color: 'white',
    border: 'none',
    borderRadius: '12px',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  }}
>
  Get Started
</button>
        </div>
      )}

<SubstackWidget />


{/* <button
              className="startButton"
              onClick={() => navigate('/about')}
              style={{
                fontSize: '1.7em',
                padding: '10px 20px',
                background: 'white',
                                color: 'black',
                border: '2px solid black',
                borderRadius: '15px',
                boxShadow: '3px 3px 0 black',
                transition: 'all 0.2s ease',
              }}
            >
              About
            </button> */}

<div className="video-container">
          <div className="video-wrapper">
            <iframe 
              src="https://www.youtube.com/embed/17GgfmXZRBg?si=LusdgUWZ4oMrznmN" 
              title="YouTube video player" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerPolicy="strict-origin-when-cross-origin" 
              allowFullScreen
            />
          </div>
          <style jsx>{`
            .video-container {
              display: flex;
              justify-content: center;
              margin-top: 5px;
              margin-bottom: 8px;
            }
            .video-wrapper {
              position: relative;
              width: 60%;
              padding-top: 40.25%; /* 4:3 Aspect Ratio for 50% width */
              overflow: hidden;
              border-radius: 20px;
              box-shadow: 0 4px 8px rgba(0,0,0,0.1);
            }
            iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
            @media (max-width: 768px) {
              .video-wrapper {
                width: 90%;
                padding-top: 56.25%; /* 4:3 Aspect Ratio for 90% width */
              }
            }
          `}</style>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', marginTop: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', alignItems: 'center' }}>
            <a href="https://www.instagram.com/supertutorai/" target="_blank" rel="noopener noreferrer">
              <img 
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1200px-Instagram_logo_2016.svg.png" 
                alt="Instagram" 
                style={{ width: '30px', height: 'auto' }} 
              />
            </a>
            <a href="https://www.youtube.com/@supertutorai" target="_blank" rel="noopener noreferrer">
              <img 
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png" 
                alt="YouTube" 
                style={{ width: '30px', height: 'auto' }} 
              />
            </a>
            <a href="https://www.linkedin.com/company/supertutor/" target="_blank" rel="noopener noreferrer">
              <img 
                src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" 
                alt="LinkedIn" 
                style={{ width: '30px', height: 'auto' }} 
              />
            </a>
            <a href="mailto:support@aisupertutor.org" style={{ fontSize: '30px', textDecoration: 'none' }}>
              📧
            </a>
          </div>
          <p style={{ fontSize: '12px', textAlign: 'center' }}>
            &copy; 2024 Supertutor. All rights reserved.
          </p>
  

  {/* Add this new div for footer links */}
  <div style={{ display: 'flex', gap: '20px', fontSize: '12px' }}>
    <p style={{ cursor: 'pointer' }} onClick={handleTermsClick}>
      Terms of service & privacy policy
    </p>
    <p style={{ cursor: 'pointer' }}>
      <Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}>
        Career Opportunities
      </Link>
    </p>
  </div>
 
          <p style={{ fontSize: '7px', textAlign: 'center', cursor: 'pointer' }} onClick={handleTermsClick}>
            Terms of service & privacy policy
          </p>


      {showTermsPopup && (
        <div
          onClick={(e) => e.stopPropagation()}
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
            background: 'white',
            padding: '20px',
            border: '1px solid black',
            borderRadius: '15px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            maxHeight: '80%',
            overflowY: 'auto',
            width: '80%',
          }}
        >
          <h2>Terms of Service</h2>
          <p>{termsOfService}</p>
          <button onClick={closeTermsPopup} style={{ marginTop: '10px', padding: '5px 10px' }}>
            Close
          </button>
        </div>
      )}

        </div>
      </>
    );
  };
  
  const CustomizeButtonsPopup = ({ buttonSettings, toggleButtonSetting, closePopup }) => {
    return (
      <div style={{/* Your existing styles */}}>
        {/* Your existing customize buttons popup content */}
      </div>
    );
  };
  
  // const TermsPopup = ({ termsOfService, closePopup }) => {
  //   return (
  //     <div style={{/* Your existing styles */}}>
  //       <h2>Terms of Service</h2>
  //       <p>{termsOfService}</p>
  //       <button onClick={closePopup}>Close</button>
  //     </div>
  //   );
  // };

  

  useEffect(() => {
    const updateMetaTags = (path) => {
      const title = document.getElementById('pageTitle');
      const description = document.getElementById('pageDescription');
      
      let newTitle = 'SUPERTUTOR AI ⭐️ Activate Your Superpowers';
      // let newDescription = 'SUPERTUTOR AI helps you activate your superpowers. Get custom educational memes, multiplayer games, and AI-powered tutoring to enhance comprehension and memory.';
      let newDescription = 'Faster than ChatGPT, smarter than your teacher, more creative than Kahoot...It\'s a bird...It\'s a plane...it\'s...Supertutor!';

      

      switch(path) {
        case '/features':
          newTitle = "SUPERTUTOR AI Features | Unlock Your Learning Potential";
          newDescription = "Explore SUPERTUTOR AI's powerful features: AI tutoring, multiplayer games, custom memes, and more. Elevate your learning experience today!";
          break;
        case '/multiplayer-games':
          newTitle = "Educational Multiplayer Games | SUPERTUTOR AI";
          newDescription = "Engage in fun, educational multiplayer games with SUPERTUTOR AI. Learn while competing with friends!";
          break;
        case '/ai-tutoring':
          newTitle = "AI-Powered Tutoring | SUPERTUTOR AI";
          newDescription = "Experience personalized AI tutoring with SUPERTUTOR AI. Get instant help and improve your understanding of complex topics.";
          break;
        case '/educational-memes':
          newTitle = "Custom Educational Memes | SUPERTUTOR AI";
          newDescription = "Enhance your learning with custom educational memes from SUPERTUTOR AI. Boost memory retention and make studying fun!";
          break;
        // Add more cases as needed
        default:
          newTitle = 'SUPERTUTOR AI ⭐️ Activate Your Superpowers';
          newDescription = 'Faster than ChatGPT, smarter than your teacher, more creative than Kahoot...It\'s a bird...It\'s a plane...it\'s...Supertutor!';
          break;
      }

      if (title) title.textContent = newTitle;
      if (description) description.content = newDescription;
    };

    updateMetaTags(location.pathname);
  }, [location]);

  // useEffect(() => {
  //   const createSparkle = () => {
  //     const sparkle = document.createElement('div');
  //     sparkle.className = 'sparkle';
  //     document.body.appendChild(sparkle);
  
  //     sparkle.style.left = `${Math.random() * window.innerWidth}px`;
  //     sparkle.style.top = `${Math.random() * window.innerHeight}px`;
  
  //     setTimeout(() => {
  //       sparkle.remove();
  //     }, 1000);
  //   };
  
  //   const interval = setInterval(createSparkle, 300);
  
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    const handleClickOutside = () => {
      if (showTermsPopup) closeTermsPopup();
    };

    if (showTermsPopup) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => document.removeEventListener('click', handleClickOutside);
  }, [showTermsPopup]);

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  //     if (currentUser) {
  //       setUser(currentUser);
  //       const userRef = doc(firestore, `users/${currentUser.uid}`);
  //       const unsubscribeFirestore = onSnapshot(userRef, (doc) => {
  //         if (doc.exists()) {
  //           const userData = doc.data();
  //           setSubscriptionPlan(userData.subscriptionPlan || '');
  //           setHasValidSubscription(userData.subscriptionPlan === 'pro' || userData.subscriptionPlan === 'beyond_pro');
  //         }
  //         setLoading(false);
  //       });

  //       return () => unsubscribeFirestore();
  //     } else {
  //       setUser(null);
  //       setSubscriptionPlan('');
  //       setHasValidSubscription(false);
  //       setLoading(false);
  //     }
  //   });

  //   return () => unsubscribe();
  // }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userRef = doc(firestore, `users/${currentUser.uid}`);
        const unsubscribeFirestore = onSnapshot(userRef, (doc) => {
          if (doc.exists()) {
            const userData = doc.data();
            setSubscriptionPlan(userData.subscriptionPlan || '');
            setHasValidSubscription(userData.subscriptionPlan === 'pro' || userData.subscriptionPlan === 'beyond_pro');
          }
          setLoading(false);
        });
  
        return () => unsubscribeFirestore();
      } else if (location.pathname === '/success') {
        // If on success page but no user, wait briefly and check again
        setTimeout(async () => {
          const user = auth.currentUser;
          if (user) {
            setUser(user);
            const userRef = doc(firestore, `users/${user.uid}`);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
              const userData = docSnap.data();
              setSubscriptionPlan(userData.subscriptionPlan || '');
              setHasValidSubscription(userData.subscriptionPlan === 'pro' || userData.subscriptionPlan === 'beyond_pro');
            }
          } else {
            console.log('No authenticated user on success page');
            navigate('/');
          }
          setLoading(false);
        }, 1000); // Wait for 1 second before checking again
      } else {
        setUser(null);
        setSubscriptionPlan('');
        setHasValidSubscription(false);
        setLoading(false);
      }
    });
  
    return () => unsubscribe();
  }, [location.pathname, navigate]);

  useEffect(() => {
    const subscriptionUpdated = localStorage.getItem('subscriptionUpdated');
    if (subscriptionUpdated === 'true') {
      localStorage.removeItem('subscriptionUpdated');
      window.location.reload();
    }
  }, []);



  useEffect(() => {
    fetchImageUrls().then(setImageUrls);

   fetchVideoUrl('demo.mp4').then(setDemoVideoUrl); // Fetch and set the demo video URL

 const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
  if (isGuest) {
    // User is a guest, skip the authentication and subscription checks
    setUser(null);
    setIsHost(false);
  } else if (currentUser) {
    // Check if the user's email is verified
    // User is authenticated, set isHost to true
    setIsHost(true);

    if (currentUser.emailVerified) {
      // Email is verified, set the user state
      setUser(currentUser);

      // Fetch the user's subscription plan from Firestore
      const userRef = doc(firestore, `users/${currentUser.uid}`);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserName(userData.name || '');
        setSubscriptionPlan(userData.subscriptionPlan || '');
        setHasValidSubscription(userData.subscriptionPlan === 'pro' || userData.subscriptionPlan === 'beyond_pro');
      }
    } else {
      // Email is not verified, check the emailVerified field in Firestore
      const userRef = doc(firestore, `users/${currentUser.uid}`);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists() && userDoc.data().emailVerified) {
        // Email is verified in Firestore, set the user state
        setUser(currentUser);

        // Fetch the user's subscription plan from Firestore
        setSubscriptionPlan(userDoc.data().subscriptionPlan || '');
      } else {
        // Email is not verified, show a message or redirect to a verification page
        console.log("Please verify your email before accessing the application.");
        // You can also display a message to the user or redirect them to a verification page
      }
    }
  } else {
    // User is signed out, set the user state to null
    // User is not authenticated, set isHost to false
    setIsHost(false);
    setUser(null);
  }
});



    const fetchButtonSettings = async () => {
      if (auth.currentUser) {
        const userRef = doc(firestore, 'users', auth.currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.buttonSettings) {
            setButtonSettings(userData.buttonSettings);
          } else {
            // Set default buttonSettings if not present in Firestore
            const defaultButtonSettings = {
              quizGame: true,
              metaphor: true,
              biggerVision: true,
              mnemonic: true,
              makeMeme: true,
              academicSources: true,
            };
            setButtonSettings(defaultButtonSettings);
            // Save the default buttonSettings to Firestore
            await setDoc(userRef, { buttonSettings: defaultButtonSettings }, { merge: true });
          }
        }
      }
    };
    fetchButtonSettings();

    return () => unsubscribe();
  }, [isGuest]);


  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  if (loading) {
    return <div></div>;
  }

  return (
    <GoogleOAuthProvider clientId="971437919820-7cp8g5m5utgh6i79l7edar80ogplsk8t.apps.googleusercontent.com">

      <div className="App" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', minHeight: '100vh', padding: '0 20px' }}>
        <header className="App-header" style={{ textAlign: 'center', maxWidth: '100%' }}>
        
     <Routes>
            <Route path="/joingame" element={<JoinGamePage />} />
            {/* <Route path="/success" element={
  user ? <SuccessPage user={user} /> : <Navigate to="/" replace />
} /> */}
{/* <Route path="/success" element={<SuccessPage />} /> */}
{/* <Route path="/success" element={<SuccessPage user={user} />} /> */}
<Route path="/success" element={
  <SuccessPage user={user} setUser={setUser} />
} />
             {/* New routes */}
  <Route path="/features" element={<Features />} />
  <Route path="/goals" element={<Goals />} />
  <Route path="/flashcards" element={
              user ? <MyFlashcards /> : <Navigate to="/" replace />
            } />

  <Route path="/multiplayer-games" element={<MultiplayerGames />} />
  <Route path="/aitutoring" element={<AITutoring />} />
  <Route path="/careers" element={<Careers />} />

  <Route path="/educational-memes" element={<EducationalMemes />} />
  <Route path="/about" element={<About />} />

  <Route path="/create-quest" element={<CreateQuest user={user} />} />

  <Route path="/quests" element={
        user ? <Quests user={user} /> : <Navigate to="/" replace />
      } />

<Route path="/askquestion" element={
    user ? (
      hasValidSubscription ? (
        <AskQuestion subscriptionPlan={subscriptionPlan} buttonSettings={buttonSettings} />
      ) : (
        <Navigate to="/upgrade" replace />
      )
    ) : (
      <Navigate to="/" replace />
    )
  } />
  <Route path="/:conversationId" element={
    user ? (
      hasValidSubscription ? (
        <AskQuestion subscriptionPlan={subscriptionPlan} buttonSettings={buttonSettings} />
      ) : (
        <Navigate to="/upgrade" replace />
      )
    ) : (
      <Navigate to="/" replace />
    )
  } />
            <Route path="/upgrade" element={
              user ? (
                hasValidSubscription ? (
                  <Navigate to="/askquestion" replace />
                ) : (
                  <UpgradePopup show={true} onClose={() => {}} subscriptionPlan={subscriptionPlan} />
                )
              ) : (
                <Navigate to="/" replace />
              )
            } />
            <Route path="/" element={
              user ? (
                isGuest ? (
                  <PartyGame isHost={false} isGuest={true} />
                ) : (
                  hasValidSubscription ? (
                    <Navigate to="/askquestion" replace />
                  ) : (
                    <Navigate to="/upgrade" replace />
                  )
                )
              ) : (
                <LandingPage 
        showSignInOptions={showSignInOptions}
        setShowSignInOptions={setShowSignInOptions}
        handleStartLearningClick={handleStartLearningClick}
        signInWithGoogle={signInWithGoogle}
        signInWithApple={signInWithApple}
        imageUrls={imageUrls}
        demoVideoUrl={demoVideoUrl}
        handleTermsClick={handleTermsClick}
        showTermsPopup={showTermsPopup}
        closeTermsPopup={closeTermsPopup}
        termsOfService={termsOfService}
        handleGoogleSuccess={handleGoogleSuccess}
        handleGoogleFailure={handleGoogleFailure}
      />
    )
  } />
  <Route path="/my-super-answers" element={
    user ? <MySuperAnswers /> : <Navigate to="/" replace />
  } />
  <Route path="/openai-stream" element={
    user ? <OpenAIStream /> : <Navigate to="/" replace />
  } />
  <Route path="/partygame" element={<PartyGame isHost={isHost} isGuest={isGuest} />} />
  {/* <Route path="/creativegame" element={
    user ? <CreativeGame /> : <Navigate to="/" replace />
  } /> */}
  <Route path="/creativegame" element={<CreativeGame />} />
  <Route path="/shared-quest/:questId" element={<SharedQuest />} />

</Routes>
         </header>

        {customizeButtonsPopup && (
          <CustomizeButtonsPopup 
            buttonSettings={buttonSettings}
            toggleButtonSetting={toggleButtonSetting}
            closePopup={() => {
              setCustomizeButtonsPopup(false);
              saveButtonSettings();
            }}
          />
        )}

        {/* {showTermsPopup && (
          <TermsPopup 
            termsOfService={termsOfService}
            closePopup={closeTermsPopup}
          />
        )} */}

{/* <TermsPopup
  showTermsPopup={showTermsPopup}
  closeTermsPopup={closeTermsPopup}
  termsOfService={termsOfService}
/> */}

        <ManageAccountPopup
          show={showManageAccountPopup}
          onClose={() => setShowManageAccountPopup(false)}
          userName={userName}
        />
      </div>
      </GoogleOAuthProvider>

  );
}
export default App;
